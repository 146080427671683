/* You can add global styles to this file, and also import other style files */
@import 'app/shared/styles/toastr';

html,
body {
  height: 100%;
  overflow-y: hidden;
}

body {
  margin: 0 !important;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

textarea {
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

.rotate-90 {
  display: inline-block;
  transform: rotate(90deg);
}

button {
  border-style: none;
}

* {
  box-sizing: border-box;
  // Colors:
  --primary: #f4f4f4;
  --secondary: #52852e;
  --danger: #f53d3d;
  --light: #f4f4f4;
  --dark: #222;
  /*Fabbrica Colors palette*/
  --menuItem-border1: #cccccc;
  --menuItem-text1: #515151;
  --menuItem-background1: #ffffff;
  --menuItem-background2: #828282;
  --fabbrica: #6db23d;
  --fabbrica-logo: #66c132;
  --fabbrica-bright: #66df1b;
  --fabbrica-dark: #52852e;
  --fabbrica-xdark: #3b5f21;
  --fabbrica-xxdark: #2f4b1b;
  --fabbrica-xpale: #e4f1da;
  --menu-darker: #404040;
  --red: #e03b3b;
  --red-dark: #a60000;
  --yellow: #f2e043;
  --black: #000000;
  --white: #ffffff;
  --blue1: #2f80ed;
  // Shadow used in boxes:
  --shadow1: 0px 0px 8px rgba(0, 0, 0, 0.4);
  --shadow2: 0px 0px 10px rgba(0, 0, 0, 0.6);
  // Font sizes:
  --t1l: 36px;
  --t1s: 24px;
  --t2l: 24px;
  --t2s: 16px;
  --t3l: 18px;
  --t3s: 14px;
  // Color for the active item automatic outlining:
  outline-color: var(--fabbrica);
  // Color of the different type of scanned items:
  --fab-document: #c94848;
  --fab-component: #f2a143;
  --fab-unit: #1497e0;
  --fab-unit-dark: #0066a0;
  --upr: #c94848;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
